<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="requisition"
  >
    <div>
      <h2 class="mg-y-2">Certification Requisition</h2>
      <el-row
        :gutter="15"
        class="mg-y-5 flex-wrap"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <el-col :span="24" :sm="12" :md="6" :lg="6">
          <el-form ref="formFilter" :model="filter" label-position="top">
            <el-form-item label="Search">
              <el-input
                clearable
                placeholder="Search"
                v-model="filter.search"
                class="search"
                @input="searchAll()"
              >
                <i slot="prefix" class="fas fa-search font-14 mg-l-1"></i>
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="24" :sm="12" :lg="12">
          <div class="is-flex ai-center flex-wrap js-end">
            <el-button
              round
              class="mg-t-2 mg-r-2 font-14 button-history"
              :loading="loadingTemplate"
              @click="downloadTemplate"
            >
              <i class="fas fa-download mg-r-1"></i>Download
              Templated</el-button
            >
            <el-upload
              class="mg-r-2 import-data"
              action="#"
              :limit="1"
              :auto-upload="false"
              :on-change="(file, fileList) => uploadDocuments(file, fileList)"
              :loading="loadingImport"
              :file-list="fileImport"
            >
              <el-button
                round
                class="mg-t-2 font-14 button-history"
                :loading="loadingImport"
              >
                Import</el-button
              >
            </el-upload>

            <el-button
              round
              @click="openHistory()"
              class="mg-t-2 font-14 button-history"
              ><i class="fas fa-history"></i> History log</el-button
            >

            <el-button
              type="warning"
              class="button-radius mg-t-2 font-14"
              @click="$router.push(`/certification-requisition/add`)"
              >+ Add Requisition (เพิ่มคำขอ)</el-button
            >
          </div>
        </el-col>
        <el-col
          :offset="12"
          :span="24"
          :xs="{ span: 24, offset: 0 }"
          :sm="12"
          :lg="12"
          class="text-right mg-t-3"
        >
          <el-switch
            v-model="switchKeep"
            active-text="แสดงข้อมูลที่ซ่อน"
            @change="handleKeepItem"
          >
          </el-switch>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :span="24">
          <TableRequisition
            :data="fetchData"
            :loading="loadingTable"
            @openDialogTrash="openDialogTrash"
            @openDialogDetail="openDialogDetail"
          />
          <p class="text-right font-12 color-69">Total : {{ total }}</p>
          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="page"
              :page-size="limit"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="15" v-if="switchKeep">
        <el-col :span="24" class="mg-y-4">
          <div class="text-bg-full">
            <span>ข้อมูลที่ซ่อน</span>
          </div>
        </el-col>
        <el-col :span="24">
          <TableRequisition
            class="table-keep"
            :type="`keep`"
            :data="fetchDataKeep"
            :loading="loadingTableKeep"
            @openDialogTrashCancle="openDialogCancel"
          />
          <p class="text-right font-12 color-69">Total : {{ totalKeep }}</p>

          <div class="text-center mg-y-4">
            <el-pagination
              :hide-on-single-page="true"
              @size-change="handleSizeChangeKeep"
              @current-change="changePageKeep"
              :current-page.sync="pageKeep"
              :page-size="limitKeep"
              layout="prev, pager, next, jumper"
              :total="totalKeep"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogKeep" width="60%" :before-close="closeKeep">
      <template slot="title">
        <p class="font-28 text-center mg-b-less color-main-1">
          ยืนยันการซ่อนข้อมูล
        </p>
      </template>
      <p class="text-center mg-t-less font-16 color-main-1">
        <span class="font-error"> * </span>
        กรุณากรอกเหตุผลที่ต้องการซ่อนข้อมูล
      </p>
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item prop="reasonForKeep">
          <el-input
            class="mg-t-5 mg-b-1"
            type="textarea"
            :rows="3"
            placeholder="เหตุผลที่ต้องการซ่อนข้อมูล"
            v-model="form.reasonForKeep"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="closeKeep" class="button-radius">ยกเลิก </el-button>
        <el-button
          type="warning"
          @click="submitKeepRequisition()"
          class="button-radius"
          >ยืนยัน
        </el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogCancelKeep" width="50%">
      <template slot="title">
        <p class="font-28 text-center mg-b-less color-main-1">
          ยกเลิกการซ่อนข้อมูล
        </p>
      </template>
      <p class="text-center mg-t-less font-16 color-main-1">
        กรุณาตรวจสอบก่อนยกเลิกการซ่อนข้อมูล
      </p>
      <span slot="footer" class="dialog-footer is-flex js-between">
        <el-button @click="dialogCancelKeep = false" class="button-radius"
          >ยกเลิก
        </el-button>
        <el-button
          type="warning"
          @click="submitCancelKeepRequisition()"
          class="button-radius"
          >ยืนยัน
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogDetail"
      width="80%"
      :before-close="closeDialogDetail"
    >
      <RequisitionDetail :form="formGet" v-if="dialogDetail" />
    </el-dialog>
    <el-drawer
      class="drawer-history"
      :visible.sync="drawerHistory"
      :before-close="closeHistory"
    >
      <div class="box-header">
        <div>
          <span> History log </span>
        </div>
      </div>
      <div id="custom-collapse" v-if="fetchHistory.length > 0">
        <virtual-list
          v-if="fetchHistory.length > 0"
          class="detail-log list-infinite scroll-touch"
          :data-key="'_id'"
          :data-sources="fetchHistory"
          :data-component="itemComponent"
          v-on:tobottom="scrollToBottom"
          :item-class="'list-item-infinite'"
        >
          <div slot="footer" class="text-center" v-if="loadingHistory">
            <i class="el-icon-loading"></i>
          </div>
        </virtual-list>
      </div>
      <p
        class="font-20 color-75 text-center mg-y-5 pd-t-1"
        v-if="fetchHistory.length == 0"
      >
        ไม่มีข้อมูล
      </p>
    </el-drawer>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import TableRequisition from "@/components/table/TableRequisition";
import RequisitionDetail from "@/views/RequisitionDetail";
import VirtualList from "vue-virtual-scroll-list";
import History from "@/components/history/History";

import { mapState } from "vuex";

export default {
  components: {
    TableRequisition,
    RequisitionDetail,
    "virtual-list": VirtualList,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      showHideCertification: (state) => state.showHideCertification,
      setCsrf: (state) => state.setCsrf,
    }),
  },
  mounted() {
    this.getRequisition();
    this.getRequisitionKeep();
    this.switchKeep = this.showHideCertification;
  },
  data() {
    return {
      loading: true,
      loadingTable: false,
      loadingTableKeep: false,
      loadingTemplate: false,
      loadingImport: false,
      filter: {
        search: "",
        auditCycle: "",
        companyType: "",
        company: "",
        standard: "",
      },
      form: {
        reasonForKeep: "",
      },
      fetchData: [],
      fetchDataKeep: [],
      page: 1,
      skip: 0,
      limit: 10,
      total: 20,
      pageKeep: 1,
      skipKeep: 0,
      limitKeep: 10,
      totalKeep: 0,
      idActive: "",
      dialogKeep: false,
      dialogCancelKeep: false,
      dialogDetail: false,
      switchKeep: false,
      formGet: {},
      rules: {
        reasonForKeep: [
          {
            required: true,
            message: "กรุณากรอกเหตุผลที่ต้องการซ่อนข้อมูล",
            trigger: "blur",
          },
        ],
      },
      drawerHistory: false,
      fetchHistory: [],
      loadingHistory: false,
      totalAll: 0,
      skipHistory: 0,
      limitHistory: 10,
      pageNowHistory: 1,
      itemComponent: History,
      fileImport: [],
    };
  },
  methods: {
    getRequisition() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;

      let obj = {
        ...this.filter,
        skip: this.skip,
        limit: this.limit,
      };

      HTTP.post(`certification/requisition`, obj)
        .then((res) => {
          if (res.data.success) {
            this.fetchData = res.data.obj;
            this.total = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTable = false;
        });
    },
    getRequisitionKeep() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        ...this.filter,
        skip: this.skip,
        limit: this.limit,
      };

      HTTP.post(`certification/requisition/keep`, obj)
        .then((res) => {
          if (res.data.success) {
            this.fetchDataKeep = res.data.obj;
            this.totalKeep = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingTableKeep = false;
        });
    },
    searchAll() {
      let debounce = null;
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getRequisition();
        this.getRequisitionKeep();
      }, 600);
    },
    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
      this.getRequisition();
    },
    changePage(page) {
      this.loadingTable = true;
      this.page = page;
      this.skip = page * this.limit - this.limit;
      this.getRequisition();
    },
    handleSizeChangeKeep(val) {
      this.limitKeep = val;
      this.changePageKeep(1);
      this.getRequisitionKeep();
    },
    changePageKeep(page) {
      this.loadingTableKeep = true;
      this.pageKeep = page;
      this.skipKeep = page * this.limitKeep - this.limitKeep;
      this.getRequisitionKeep();
    },
    openDialogTrash(id) {
      this.idActive = id;
      this.dialogKeep = true;
    },
    openDialogCancel(id) {
      this.idActive = id;
      this.dialogCancelKeep = true;
    },
    submitKeepRequisition() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          let obj = {
            certificationId: this.idActive,
            reasonForKeep: this.form.reasonForKeep,
          };

          this.loadingTable = true;
          this.loadingTableKeep = true;

          HTTP.put(`certification/requisition/keep`, obj)
            .then((res) => {
              if (res.data.success) {
                this.getRequisition();
                this.getRequisitionKeep();
                this.$notify({
                  title: "ซ่อนข้อมูลสำเร็จ!",
                  type: "success",
                  customClass: "success",
                });
              }
            })
            .catch((e) => {
              if (typeof e.response != "undefined") {
                if (e.response.data.status == 401) {
                  this.checkAuth();
                }
              }
            })
            .finally(() => {
              this.idActive = "";
              this.dialogKeep = false;
              this.form.reasonForKeep = "";
            });
        }
      });
    },
    submitCancelKeepRequisition() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        certificationId: this.idActive,
      };

      this.loadingTable = true;
      this.loadingTableKeep = true;
      HTTP.put(`certification/requisition/keep/cancel`, obj)
        .then((res) => {
          if (res.data.success) {
            this.getRequisition();
            this.getRequisitionKeep();

            this.$notify({
              title: "ยกเลิกการซ่อนข้อมูลสำเร็จ!",
              type: "success",
              customClass: "success",
            });
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.idActive = "";
          this.dialogCancelKeep = false;
          this.form.reasonForKeep = "";
        });
    },
    async openDialogDetail(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      HTTP.get(`certification/requisition/` + id)
        .then(async (res) => {
          if (res.data.success) {
            this.dialogDetail = true;
            this.formGet = res.data.obj;
            let filterFood = await res.data.obj.companyType
              .filter((row) => row.type == 1)
              .map((mapRow) => {
                return { ...mapRow, valCatFoodSafety: "" };
              });

            let filterISO = await res.data.obj.companyType
              .filter((row) => row.type == 2)
              .map((mapRow) => {
                return { ...mapRow, valCatISO: "" };
              });

            this.formGet = {
              ...res.data.obj,
              checkboxFood: filterFood.length > 0 ? true : false,
              checkboxISO: filterISO.length > 0 ? true : false,
              conditionsFood: await filterFood,
              conditionsISO: await filterISO,
            };
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        });
    },

    handleKeepItem() {
      if (this.switchKeep == true) {
        this.$store.commit("SET_SHOW_CERTIFICATION_HIDE", true);
      } else {
        this.$store.commit("SET_SHOW_CERTIFICATION_HIDE", false);
      }
    },
    closeDialogDetail() {
      this.dialogDetail = false;
      this.formGet = false;
    },

    openHistory() {
      this.drawerHistory = true;
      this.getHistoryLog();
    },
    getHistoryLog(skip, limit, type) {
      let obj = {
        type: 1,
        skip: skip != undefined ? skip : this.skipHistory,
        limit: limit != undefined ? limit : this.limitHistory,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`user/history/manage`, obj)
        .then((res) => {
          if (res.data.success) {
            if (type != "scroll") {
              this.fetchHistory = res.data.obj;
            } else {
              res.data.obj.forEach((row) => {
                this.fetchHistory.push({ ...row });
              });
            }
            this.totalAll = res.data.total;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },

    closeHistory() {
      this.drawerHistory = false;
      this.skipHistory = 0;
      this.pageNowHistory = 1;
    },
    async scrollToBottom() {
      if (this.fetchHistory.length < this.totalAll) {
        this.skipHistory = this.pageNowHistory * this.limitHistory;
        this.loadingHistory = true;
        await this.getHistoryLog(this.skipHistory, this.limitHistory, "scroll");
        this.pageNowHistory++;
      } else {
        this.loadingHistory = false;
      }
    },
    closeKeep() {
      this.dialogKeep = false;
      this.$refs["ruleForm"].resetFields();
    },

    async uploadDocuments(file, fileList) {
      this.loadingImport = true;
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (
            typeThis[indexOfFile] == "xlsx" ||
            typeThis[indexOfFile] == "xls" ||
            typeThis[indexOfFile] == "csv"
          ) {
            if (typeThis[0] == "certification_requisition") {
              let obj = {
                name: file.name,
                file: file,
              };
              this.fileImport.push(obj);
              this.loadingImport = true;

              await this.importDocuments();
            } else {
              let index = fileList.findIndex((a) => a.uid == file.uid);
              fileList.splice(index, 1);
              this.$message({
                message: "ชื่อไฟล์อัพโหลดไม่ถูกต้อง",
                type: "error",
                duration: 4000,
              });
              this.loadingImport = false;
            }
          } else {
            this.$message({
              message: "กรุณาเลือกไฟล์นามสกุลเป็น CSV หรือ XLSX เท่านั้น",
              type: "error",
              duration: 4000,
            });
            let index = fileList.findIndex((a) => a.uid == file.uid);
            fileList.splice(index, 1);
            this.loadingImport = false;
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
          this.loadingImport = false;
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
        this.loadingImport = false;
      }
    },
    async importDocuments() {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": this.setCsrf,
        },
      };
      var formData = new FormData();
      
      formData.append("_csrf", this.setCsrf);
      if (this.fileImport[0].file != undefined) {
        formData.append("file", this.fileImport[0].file.raw);
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      }
      HTTP.post(`certification/requisition/import`, formData, config)
        .then(async (res) => {
          if (res.data.success) {
            this.$notify({
              title: "Import Certification Requisition สำเร็จ!",
              type: "success",
              customClass: "success",
            });

            this.fileImport = [];
            await this.getRequisition();
          } else {
            if (res.data.data == "file name column does not match") {
              this.$message({
                message: "ชื่อหัวตารางไม่ถูกต้อง",
                type: "error",
                duration: 4000,
              });
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.data.status == 401) {
              this.checkAuth();
            }
          }
        })
        .finally(() => {
          this.loadingImport = false;
          this.fileImport = [];
        });
    },
    downloadTemplate() {
      this.loadingTemplate = true;
      let columnsTemplate = [
        "Company name",
        "ID",
        "Address Of Client",
        "Contact person name",
        "Phone number",
        "E-mail",
      ];

      let csvContent = "";
      csvContent += [columnsTemplate.join(",")]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
      );
      link.setAttribute("download", "certification_requisition.csv");
      this.$notify({
        title: "Download Template ข้อมูลสำเร็จ!",
        type: "success",
        customClass: "success",
      });
      link.click();
      setTimeout(() => {
        this.loadingTemplate = false;
      }, 200);
    },
  },
};
</script>
