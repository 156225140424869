<template>
  <div class="mg-y-5">
    <el-form label-position="top" :model="form" ref="ruleForm" status-icon>
      <ViewStep :form="form" :type="'view'" :haveEdit="false" />
    </el-form>
  </div>
</template>

<script>
import "@/mixin/DefaultTemplate";
import ViewStep from "@/components/requisition/add/Step4";

import { mapState } from "vuex";

export default {
  components: {
    ViewStep,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
};
</script>
