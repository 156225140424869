<template>
  <div>
    <el-table
      :data="data"
      class="table-normal animate__animated animate__fadeIn animate__slow w-100"
      :class="data.length === 0 ? 'empty' : ''"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <img alt="logo" src="@/assets/logo.png" />
        <p>ไม่พบข้อมูล</p>
      </template>
      <el-table-column min-width="250" align="center" prop="companyName">
        <template slot="header">
          <div class="two-line">
            <p>Company name</p>
            <p>(ชื่อบริษัท)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="140" align="center" prop="juristicId">
        <template slot="header">
          <div class="two-line">
            <p>ID</p>
            <p>(เลขนิติบุคคล)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="200"
        prop="address"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div class="two-line">
            <p>Address Of Client</p>
            <p>(ที่อยู่)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="180" align="center" prop="contactPerson">
        <template slot="header">
          <div class="two-line">
            <p>Contact person name</p>
            <p>(ชื่อผู้ติดต่อ)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" align="center" prop="phone">
        <template slot="header">
          <div class="two-line">
            <p>Phone number</p>
            <p>(เบอร์โทร)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="200" align="center" prop="email">
        <template slot="header">
          <div class="two-line">
            <p>E-mail</p>
            <p>(อีเมล)</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        min-width="200"
        align="center"
        prop="reasonForKeep"
        v-if="type == 'keep'"
      >
        <template slot="header">
          <div class="two-line">
            <p>Reason</p>
            <p>(หมายเหตุ)</p>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="160px">
        <template slot="header">
          <div class="two-line">
            <p>Action</p>
            <p>(จัดการ)</p>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="box-icon" v-if="type == 'keep'">
            <i class="fas fa-archive"></i>
            <el-button
              type="not-approve"
              class="button-radius"
              @click="$emit('openDialogTrashCancle', scope.row._id)"
            >
              <span> ยกเลิกการซ่อน </span>
            </el-button>
          </div>
          <div class="box-icon" v-else>
            <i
              class="fas fa-eye"
              @click="$emit('openDialogDetail', scope.row._id)"
            ></i>
            <i
              class="fas fa-edit"
              @click="
                $router.push(`/certification-requisition/edit/` + scope.row._id)
              "
            ></i>
            <i
              class="fas fa-archive keep"
              @click="$emit('openDialogTrash', scope.row._id)"
            ></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      default: Array,
      return: [],
    },
    loading: {
      type: Boolean,
      return: false,
    },
    type: {
      type: String,
      return: "",
    },
  },
};
</script>
